var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.address_detail_forms),function(form,index){return [_c('v-card-title',{key:index + form.name + '-heading',staticClass:"px-0 mx-0",staticStyle:{"border-bottom":"solid black 1px"}},[_c('h3',{staticClass:"font-weight-regular text-uppercase"},[_c('v-icon',{class:form.icon_class,attrs:{"color":"primary darken-1"}},[_vm._v(_vm._s(form.icon))]),_vm._v(" "+_vm._s(form.title)+" ")],1),_c('v-spacer'),(form.is_hideable)?_c('v-btn',{attrs:{"icon":"","color":form.is_showing ? '' : 'primary'},on:{"click":function($event){form.is_showing
            ? (form.is_showing = false)
            : (form.is_showing = true)}}},[_c('v-icon',[_vm._v(" "+_vm._s(form.is_showing ? "mdi-arrow-up-drop-circle" : "mdi-arrow-down-drop-circle")+" ")])],1):_vm._e()],1),(
        (form.is_hideable && form.is_showing) || form.is_hideable == false
      )?_c('v-card-text',{key:index + form.name + '-form'},[_c('div',{staticClass:"ma-2 mt-6 show-form-labels"},[_c('VFormBase',{attrs:{"col":{ cols: 12 },"model":_vm.form_data,"schema":_vm.form_structure[form.name]},on:{"errorsList":function (val) { return _vm.parent.updateErrorsList(index, val); },"click":_vm.handleFormInputClick,"input":_vm.handleFormInputClick},scopedSlots:_vm._u([{key:"slot-top-array-form-base-drivers_info",fn:function(ref){
      var idx = ref.idx;
return [_c('div',{staticClass:"d-flex justify-space-between pa-4 ma-2 bold title"},[_c('div',[_vm._v("Driver "+_vm._s(idx + 1))]),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeDriver(idx)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"slot-top-array-form-base-vehicles_info",fn:function(ref){
      var idx = ref.idx;
return [_c('div',{staticClass:"d-flex justify-space-between pa-4 ma-2 bold title"},[_c('div',[_vm._v("Vehicle "+_vm._s(idx + 1))]),_c('v-btn',{attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.removeVehicle(idx)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"slot-item-key-form-base-vehicle_driver_assignment",fn:function(ref){return [_c('div',{staticClass:"d-flex pa-0 ma-0"},[_c('v-simple-table',{staticStyle:{"width":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Drivers")]),_vm._l((_vm.form_data.vehicles_info),function(vehicle){return [(
                            _vm.form_data.vehicles_info.length &&
                            vehicle.year &&
                            vehicle.make &&
                            vehicle.model
                          )?[_c('th',{key:((vehicle.year) + " " + (vehicle.make) + " " + (vehicle.model))},[_vm._v(" "+_vm._s(((vehicle.year) + " " + (vehicle.make) + " " + (vehicle.model)))+" ")])]:_vm._e()]})],2)]),_c('tbody',[_vm._l((_vm.form_data.drivers_info),function(driver,driverIndex){return [(
                          _vm.form_data.drivers_info.length &&
                          driver.first_name &&
                          driver.last_name
                        )?[_c('tr',{key:((driver.first_name) + " " + (driver.last_name))},[_c('td',[_vm._v(" "+_vm._s(((driver.first_name) + " " + (driver.last_name)))+" ")]),_vm._l((_vm.form_data.vehicles_info),function(vehicle,vehicleIndex){return [(
                                _vm.form_data.vehicles_info.length &&
                                vehicle.year &&
                                vehicle.make &&
                                vehicle.model
                              )?[_c('td',{key:((vehicle.year) + " " + (vehicle.make) + " " + (vehicle.model))},[_c('v-text-field',{staticClass:"pa-0 my-4",attrs:{"type":"number","suffix":"%","clearable":"","solo":"","outlined":"","dense":"","rules":[_vm.required()]},on:{"input":function($event){return _vm.vehicleDriverAssignmentCalculation(
                                      vehicleIndex,
                                      driverIndex
                                    )}},model:{value:(
                                    _vm.form_data.vehicle_driver_assignment[
                                      vehicleIndex
                                    ][driverIndex]
                                  ),callback:function ($$v) {_vm.$set(_vm.form_data.vehicle_driver_assignment[
                                      vehicleIndex
                                    ], driverIndex, $$v)},expression:"\n                                    form_data.vehicle_driver_assignment[\n                                      vehicleIndex\n                                    ][driverIndex]\n                                  "}})],1)]:_vm._e()]})],2)]:_vm._e()]}),_c('tr',[_c('td',{staticClass:"text-start"},[_vm._v("Total Usage Per Vehicle")]),_vm._l((_vm.form_data.vehicle_driver_assignment),function(vehicle,vehicleIndex){return [(vehicle.total)?_c('td',{key:vehicleIndex,staticClass:"text-start"},[_vm._v(" "+_vm._s(vehicle.total)+"% ")]):_vm._e()]})],2)],2)]},proxy:true}],null,true)})],1)]}}],null,true)})],1)]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }