<template>
  <section class="pa-2 address-details">
    <v-form @submit.prevent v-model="isValidForm" ref="form">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-card flat tile elevation-0 class="transparent">
            <v-card-title
              class="px-0 mx-0"
              style="border-bottom: solid black 1px"
            >
              <h1 class="font-weight-regular text-capitalize">
                <v-icon>mdi-form-select</v-icon>Overview
              </h1>
              <v-spacer></v-spacer>
              <v-btn
                icon
                :color="showHiddenRequiredFields ? 'error' : 'primary'"
                @click="
                  showHiddenRequiredFields
                    ? (showHiddenRequiredFields = false)
                    : (showHiddenRequiredFields = true)
                "
              >
                <v-icon>
                  {{ showHiddenRequiredFields ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
              </v-btn>
              <deleteApplicationBtn :application="application" />
            </v-card-title>
            <v-card-text>
              <div class="d-flex column justify-end">
                <v-btn
                  v-for="(addressDetailFinder, index) in addressDetailsFinders"
                  :title="addressDetailFinder.name"
                  @click="addressDetailFinder.cbf"
                  :key="index"
                  icon
                >
                  <img
                    height="25px"
                    :src="
                      require(`@/assets/images/${addressDetailFinder.icon}`)
                    "
                  />
                </v-btn>
              </div>
              <div class="ma-x mt-6">
                <FormMaker
                  :col="{ cols: 12 }"
                  :model="form_data"
                  :schema="form_structure.agent_info"
                  @errorsList="(val) => updateErrorsList(1122, val)"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mx-4 mt-0 mb-4" justify="center" align="center">
        <v-col
          v-for="(application_type, index) in agentApplicationTypeList"
          :cols="colSize(2, 2, 4, 12)"
          :key="index"
          class="applications-page-btn"
        >
          <v-btn
            @click="changeCreateOrGoToApplicationType(application_type)"
            :color="
              form_data.application_type == application_type ? 'secondary' : ''
            "
            :class="
              form_data.application_type == application_type
                ? 'active'
                : 'btn-prompt'
            "
            :ripple="isApplicationLaunchAble"
            class="text-uppercase elevation-0"
            large
            block
            >{{ application_type }}</v-btn
          >
        </v-col>
      </v-row>
      <ApplicationFormContainer>
        <template v-slot:application-form="">
          <component
            v-if="form_data && form_data.application_type"
            :is="`${form_data.application_type}ApplicationForm`"
            :application_id="application_id"
            :parent="$this"
          ></component>
        </template>
      </ApplicationFormContainer>

      <v-row
        v-if="
          application_details_form_selected_page == 0 && isApplicationLaunchAble
        "
      >
        <v-col>
          <v-btn
            @click="updateApplication()"
            color="primary"
            outlined
            block
            text
            >Update Application</v-btn
          >
        </v-col>
      </v-row>
      <v-row v-if="!isApplicationLaunchAble">
        <v-col>
          <v-btn
            @click="resetApplication()"
            color="primary"
            outlined
            block
            text
          >
            Reset Application For Relaunch
          </v-btn>
        </v-col>
      </v-row>
      <div
        v-if="isApplicationLaunchAble"
        class="center-floating-btn launch-btn d-flex column"
      >
        <div @click="launchApplication()" class="pr-1">
          <v-img :src="require('@/assets/images/launch-quote-btn.png')"></v-img>
        </div>
        <div @click="updateApplication()">
          <v-img :src="require('@/assets/images/save-btn.png')"></v-img>
        </div>
      </div>
      <v-row
        class="center-floating-btn new-application-btn text-capitalize pa-0"
        v-if="application && application.payload"
        @click="refreshPage()"
        no-gutters
      >
        <v-col class="px-1 ma-0" cols="12">
          {{ `Launch Count ${form_data.application_relaunch_count} ` }}
          <v-divider></v-divider>
          {{
            `${application.application_id} ${application.payload.status.replace(
              /_/g,
              " "
            )} `
          }}
          <template v-if="application.payload.time_taken_to_complete">
            <v-divider></v-divider>
            {{ `Completed In ${application.payload.time_taken_to_complete} ` }}
          </template>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { debounce } from "lodash";
import FormMaker from "@/components/FormMaker";
import { required } from "@/plugins/validationRules.js";

import { mapState } from "vuex";
import ApplicationFormContainer from "@/components/base/ApplicationFormContainer";
import floodApplicationForm from "@/components/forms/application/flood";
import homeApplicationForm from "@/components/forms/application/home";
import lifeApplicationForm from "@/components/forms/application/life";
import petApplicationForm from "@/components/forms/application/pet";
import autoApplicationForm from "@/components/forms/application/auto";
import deleteApplicationBtn from "@/components/general/deleteApplicationBtn";
import floodZones from "@/assets/floodZonesList";
import states from "@/assets/statesList";

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getApplicationByIdDebounced(vm.$route.params.application_id);
    });
  },
  beforeRouteUpdate(to, from, next) {
    next((vm) => {
      vm.getApplicationByIdDebounced(vm.$route.params.application_id);
    });
  },
  props: {
    application_id: {
      required: true,
    },
  },
  components: {
    FormMaker,
    ApplicationFormContainer,
    floodApplicationForm,
    homeApplicationForm,
    lifeApplicationForm,
    autoApplicationForm,
    petApplicationForm,
    deleteApplicationBtn,
  },
  data() {
    return {
      floodZones,
      states,
      errorsList: [],
      showHiddenRequiredFields: false,
      isValidForm: false,
    };
  },
  computed: {
    $this() {
      return this;
    },
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    agentApplicationTypeList() {
      // if (this.currentUserAccesses?.primary_products?.length) {
      // 	return this.currentUserAccesses?.primary_products;
      // }

      return this.$store.state.application.application_types.map(
        (type) => type.value
      );
    },
    isApplicationLaunchAble() {
      return (
        this.application &&
        this.application.payload &&
        this.application.payload.status != "launched" &&
        this.application.payload.status != "complete"
      );
    },
    form_structure() {
      return {
        agent_info: {
          client_name_id: {
            type: "text",
            name: "client_name_id",
            class: "custom-style",
            label: "Client Name & Application ID",
            disabled: true,
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          rocket_agent: {
            type: "text",
            name: "rocket_agent",
            class: "custom-style",
            label: "Rocket Agent & ID",
            disabled: true,
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          address: {
            type: "text",
            name: "address",
            class: "custom-style",
            label: "Searched Address",
            disabled: true,
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          immediate_coverage: {
            type: "switch",
            name: "immediate_coverage",
            label:
              this.form_data.application_type == "flood"
                ? "Immediate Coverage?"
                : "New Purchase",
            hideDetails: false,
            inset: true,
            color: "primary",
            dense: true,
            trueValue: true,
            falseValue: false,
            col: { cols: 6, sm: 6, md: 2, lg: 2, xl: 2 },
            rules: [],
          },
          effective_date: {
            type: "text",
            name: "effective_date",
            typeInt: "date",
            class: "custom-style",
            label: "Effective Date",
            clearable: false,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            min: this.$moment().format("YYYY-MM-DD"),
            max: this.$moment().add(1, "months").format("YYYY-MM-DD"),
            newDateFormate: this.globalDateFormate,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 6, sm: 6, md: 2, lg: 2, xl: 2 },
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          application_source: {
            type: "text",
            name: "application_source",
            class: "custom-style pa-0 ma-0",
            label: "Application Source",
            tooltip: this.form_data?.is_cross_sale
              ? `It's a cross sale application. </br> ${
                  this.application?.payload?.useable_details
                    ?.application_provider
                    ? this.application?.payload?.useable_details
                        ?.application_provider + " Is Application Provider."
                    : ""
                }`
              : `It's a primary application. </br> ${
                  this.application?.payload?.useable_details
                    ?.application_provider
                    ? this.application?.payload?.useable_details
                        ?.application_provider + " Is Application Provider."
                    : ""
                }`,
            prependIcon: this.form_data?.is_cross_sale
              ? "mdi-spider-web"
              : "mdi-target",
            disabled: true,
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: true,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 12, md: 2, lg: 2, xl: 2 },
            rules: [],
          },
          purchase_date: {
            hidden:
              this.form_data.application_type == "flood" ||
              !!this.form_data.immediate_coverage,
            type: "text",
            name: "purchase_date",
            typeInt: "date",
            class: "custom-style",
            label: "Purchase Date",
            clearable: false,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            newDateFormate: this.globalDateFormate,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 6, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          currently_insured: {
            hidden:
              this.form_data.application_type == "flood" ||
              !!this.form_data.immediate_coverage,
            type: "switch",
            name: "currently_insured",
            label: "Currently Insured",
            hideDetails: false,
            inset: true,
            color: "primary",
            dense: true,
            trueValue: true,
            falseValue: false,
            col: { cols: 6, sm: 6, md: 2, lg: 2, xl: 2 },
            rules: [],
          },
          current_policy_expiration_date: {
            hidden:
              this.form_data.application_type == "flood" ||
              !!this.form_data.immediate_coverage ||
              !!!this.form_data.currently_insured,
            type: "text",
            name: "current_policy_expiration_date",
            typeInt: "date",
            class: "custom-style",
            label: "Current Policy Exp Date",
            clearable: false,
            outlined: true,
            noTitle: true,
            solo: true,
            flat: true,
            newDateFormate: this.globalDateFormate,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 6, sm: 6, md: 4, lg: 4, xl: 4 },
            rules: [required()],
            menu: {
              closeOnContentClick: false,
              nudgeRight: 200,
              maxWidth: "290px",
              minWidth: "290px",
            },
          },
          address1: {
            hidden: this.hideEmptyRequiredFields("address1"),
            type: "text",
            name: "address1",
            label: "Address1",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          address2: {
            hidden: this.hideEmptyRequiredFields("address1"),
            type: "text",
            name: "address2",
            label: "Address2",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [],
          },
          state: {
            hidden: this.hideEmptyRequiredFields("state"),
            type: "select",
            name: "state",
            class: "custom-style",
            label: "State",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            color: "primary",
            elevation: 0,
            // value: this.form_data.state,
            items: this.states,
            itemText: "name",
            itemValue: "alpha-2",
            menuProps: {
              auto: true,
              overflowY: true,
              offsetY: true,
              closeOnClick: false,
              closeOnContentClick: false,
              disableKeys: true,
              openOnClick: false,
              maxHeight: 304,
            },
            dense: true,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          city: {
            hidden: this.hideEmptyRequiredFields("city"),
            type: "text",
            name: "city",
            label: "City",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          zip: {
            hidden: this.hideEmptyRequiredFields("zip"),
            type: "text",
            name: "zip",
            label: "Zip",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            mask: "#####",
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          county: {
            hidden: this.hideEmptyRequiredFields("county"),
            type: "text",
            name: "county",
            label: "County",
            clearable: false,
            outlined: true,
            solo: true,
            flat: true,
            hideDetails: false,
            class: "custom-style",
            color: "primary",
            elevation: 0,
            dense: true,
            col: { cols: 12, sm: 6, md: 6, lg: 6, xl: 6 },
            rules:
              ["Agent Portal"].includes(this.form_data.application_source) &&
              ["flood"].includes(this.form_data.application_type)
                ? [required()]
                : [],
          },
        },
      };
    },
    addressDetailsFinders() {
      return [
        {
          name: "Google Maps",
          icon: "google-maps-icon.png",
          cbf: this.openPropertyInGoogleMaps,
        },
        {
          name: "Zillo",
          icon: "zillo-icon.png",
          cbf: this.openPropertyInZillo,
        },
        {
          name: "Flood Factor",
          icon: "flood-factor-icon.png",
          cbf: this.openPropertyInFloodFactor,
        },
        {
          name: "True People Search",
          icon: "true-people-search-icon.png",
          cbf: this.openPropertyInTruePeopleSearch,
        },
        {
          name: "Fema",
          icon: "fema-icon.png",
          cbf: this.openPropertyInFema,
        },
      ];
    },
  },
  watch: {
    "form_data.application_type"(newVal, oldVal) {
      if (newVal != oldVal) {
        // console.log("formValidationReset");
        this.$refs.form.resetValidation();
        this.errorsList = [];
        this.isValidForm = true;
        this.$refs.form.validate();
      }
    },
    "form_data.immediate_coverage"(newVal, oldVal) {
      if (newVal != oldVal) {
        this.form_data.immediate_coverage = !!newVal;
        if (!!newVal == false) {
          this.form_data.purchase_date = null;
          this.form_data.currently_insured = false;
          this.form_data.current_policy_expiration_date = null;
        }
      }
    },
    "form_data.currently_insured"(newVal, oldVal) {
      if (newVal != oldVal) {
        this.form_data.currently_insured = !!newVal;
        if (!!newVal == false) {
          this.form_data.current_policy_expiration_date = null;
        }
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    hideEmptyRequiredFields(val) {
      if (this.showHiddenRequiredFields) return false;

      if (this.form_data && this.form_data.api_address_data)
        return (
          this.form_data.api_address_data[val] &&
          this.form_data.api_address_data[val] != ""
        );
      return false;
    },
    openPropertyInGoogleMaps() {
      let address = this.application.payload.address;
      let url = `https://www.google.com/maps/place/${address}`;
      window.open(url, "_blank");
    },
    openPropertyInZillo() {
      let address = this.application.payload.address;
      // `https://www.zillow.com/homes/216-SIGNAL-LN,-PORT-ST-JOE,-FL-32456_rb/`
      let url = `https://www.zillow.com/homes/${address
        .toString()
        .replace(/ /g, "-")}_rb/`;
      window.open(url, "_blank");
    },
    openPropertyInFema() {
      let address = encodeURI(this.form_data.address.toString());
      // `https://msc.fema.gov/portal/search?AddressQuery=738%20Anchor%20Dr%2C%20Sanibel%2C%20FL%2033957#searchresultsanchor`
      let url = `https://msc.fema.gov/portal/search?AddressQuery=${address}`;
      window.open(url, "_blank");
    },
    openPropertyInTruePeopleSearch() {
      let name = "";
      if (
        `${this.form_data.first_name} ${this.form_data.last_name}` !=
        "null null"
      )
        name = `${this.form_data.first_name} ${this.form_data.last_name}`;
      // `https://www.truepeoplesearch.com/results?name=Garrett%20Mitchell&citystatezip=West%20Palm%20Beach`
      let url = `https://www.truepeoplesearch.com/results?name=${name}&citystatezip=${this.form_data.city} ${this.form_data.state} ${this.form_data.zip}`;
      window.open(url, "_blank");
    },
    openPropertyInFloodFactor() {
      let address = this.application.payload.address;
      helpers.copyText(address);
      this.$notify({
        group: "msg",
        title: "Address Copied",
        text: address,
        type: "success",
      });
      let url = `https://floodfactor.com/`;
      window.open(url, "_blank");
    },
    isFormDetailsValid() {
      this.$refs.form.validate();
      // console.log("form validation fails: ", !this.isValidForm);
      if (!this.isValidForm) return false;

      let noErrorsFound = true;
      let foundedError = this.errorsList.find((error) => {
        if (error && error.length) return true;
        return false;
      });

      if (foundedError && !!!this.form_data[foundedError[0].input])
        noErrorsFound = false;

      // console.log("custom form validation fails: ", !noErrorsFound);
      return noErrorsFound;
    },
    updateErrorsList(index, errors) {
      // console.log("error added from", index, errors);
      this.errorsList[index] = errors;
    },
    generateFoundationTypeComputed() {
      if (["Stilts Pilings"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${this.form_data.foundation_type} - ${this.form_data.stilts_pilings}`;
      } else if (["Garage"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.foundation_type
        } ${this.mapYesNoToFinishedUnfinished(this.form_data.garage_finished)}`;
      } else if (["Basement"].includes(this.form_data.foundation_type)) {
        this.form_data.foundation_type_computed = `${
          this.form_data.basement_type
        } - ${this.mapYesNoToFinishedUnfinished(
          this.form_data.basement_finished
        )}`;
      } else {
        this.form_data.foundation_type_computed =
          this.form_data.foundation_type;
      }
      // console.log(this.form_data.foundation_type_computed);
    },
    mapYesNoToFinishedUnfinished(val) {
      return val == "Yes" ? "Finished" : "Unfinished";
    },
    async updateApplication() {
      if (!this.isApplicationLaunchAble) {
        this.$notify({
          group: "msg",
          title: "Message",
          text: "Application Already Launched, Thanks.",
          type: "warring",
        });
        return;
      }
      this.isFormDetailsValid();

      // console.log(
      // 	this.isFormDetailsValid(),
      // 	this.isValidForm,
      // 	this.form_data,
      // 	this.errorsList
      // );

      this.generateFoundationTypeComputed();
      let data = {
        application_id: this.application.id,
        address_details: this.form_data,
      };
      return await this.post(
        `${this.baseUrl}/agent-portal/update-application`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);

          if (data.data && data.data.application) {
            this.$store.state.application.application = data.data.application;
          }
        }
        return data;
      });
    },
    async launchApplication() {
      if (!this.isApplicationLaunchAble) {
        this.$notify({
          group: "msg",
          title: "Message",
          text: "Application Already Launched, Thanks.",
          type: "warring",
        });
        return;
      }

      // console.log(
      // 	this.isFormDetailsValid(),
      // 	this.isValidForm,
      // 	this.form_data,
      // 	this.errorsList
      // );

      if (this.isFormDetailsValid() == false) {
        this.$notify({
          group: "msg",
          title: "Error",
          text: "Required Information is missing or Invalid info.",
          type: "error",
        });
        return;
      }

      this.generateFoundationTypeComputed();
      let data = {
        application_id: this.application.id,
        address_details: this.form_data,
      };
      return await this.post(
        `${this.baseUrl}/agent-portal/launch-application`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);

          if (data.data && data.data.application) {
            this.$store.state.application.application = data.data.application;
            this.application_details_form_selected_page = 1;
          }
        }
        return data;
      });
    },
    async resetApplication() {
      if (!confirm("Are you sure you want to reset application for relaunch?"))
        return false;

      let data = {
        application_id: this.application.id,
      };

      return await this.post(
        `${this.baseUrl}/agent-portal/reset-application`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);
          this.getApplicationByIdDebounced(this.$route.params.application_id);
        }
        return data;
      });
    },
    async duplicateApplicationToType(applicationType) {
      if (
        !confirm(
          `Are you sure you want to duplicate this application of ${applicationType} type application?`
        )
      ) {
        return false;
      }

      // return false;

      let data = {
        application_id: this.application.id,
        application_type: applicationType,
      };

      return await this.post(
        `${this.baseUrl}/agent-portal/duplicate-application`,
        data,
        true
      ).then((data) => {
        if (data) {
          this.showMessageFromResponseDta(data);

          if (data?.data?.application?.application_id) {
            this.$router.push({
              name: "application-details",
              params: { application_id: data.data.application?.application_id },
            });
            this.getApplicationByIdDebounced(
              data.data.application?.application_id
            );
          }
        }
        return data;
      });
    },
    async changeCreateOrGoToApplicationType(requestedApplicationType) {
      if (
        this.currentUserAccesses?.primary_products?.length &&
        !this.currentUserAccesses?.primary_products.includes(
          requestedApplicationType
        )
      ) {
        alert(
          `You have no access to ${requestedApplicationType} type applications.`
        );
        return true;
      }

      let currentApplicationType = this.application?.application_type;
      let applicationTypes = this.application?.related_applications;

      let requestedTypeExists = applicationTypes?.find(
        (typePayload) =>
          requestedApplicationType == typePayload.related_application_type
      );

      // console.log(
      // 	applicationTypes,
      // 	requestedTypeExists,
      // 	requestedApplicationType,
      // );

      if (currentApplicationType != requestedApplicationType) {
        if (
          requestedTypeExists
          // &&
          // confirm(
          // 	`Are you sure you want to goto ${requestedApplicationType} type of this application?`
          // )
        ) {
          this.application_details_form_selected_page = 0;
          this.$router.push({
            name: "application-details",
            params: {
              application_id:
                requestedTypeExists?.related_application_id_string,
            },
          });

          this.getApplicationByIdDebounced(
            requestedTypeExists?.related_application_id_string
          );

          // this.refreshPage();
          return false;
        }
        if (!requestedTypeExists) {
          this.duplicateApplicationToType(requestedApplicationType);
          return false;
        }
      }

      this.form_data.application_type = requestedApplicationType;
    },
    getApplicationByIdDebounced: debounce(
      function (applicationId) {
        this.getApplicationById(applicationId);
      },
      1000,
      { leading: true, trailing: false }
    ),
    getApplicationById(applicationId) {
      this.$store
        .dispatch("application/getApplicationById", applicationId)
        .then((val) => {
          if (val && val.code == 200) {
            if (this.isApplicationLaunchAble == false) {
              this.application_details_form_selected_page = 1;
            } else {
              this.application_details_form_selected_page = 0;
            }
          } else this.$router.push({ name: "agent-portal" });
        });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/variables.scss";
#agent-portal {
  .key-application_source {
    .v-input__prepend-outer {
      padding: 5px 2px 0 2px;
      margin: 0px;
    }
  }
  .primary-color {
    color: $light-primary-color !important;
  }
  .center-floating-btn {
    position: fixed;
    height: 52px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    padding: 10px;
    z-index: 10;
    cursor: pointer;
    background-color: #4a4a4a;
    align-items: center;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    vertical-align: middle;
    white-space: nowrap;
  }
  .launch-btn {
    right: 0;
    margin-right: 0;
    margin-bottom: 10px;
    border-radius: 4px 0 0 4px;
  }
  .new-application-btn {
    height: 60px;
    left: 0;
    margin-left: 0;
    margin-bottom: 0 !important;
    padding: 5px;
    font-size: 12px;
    background-color: rgb(231, 231, 231);
    border-top: solid 1px $light-primary-color;
    border-right: solid 1px $light-primary-color;
    color: $light-primary-color;
  }
  .applications-page-btn {
    .btn-prompt {
      background-color: rgb(231, 231, 231) !important;
      background: linear-gradient(
        180deg,
        rgb(238, 238, 238) 0%,
        rgb(255, 255, 255) 100%
      );
    }
    .btn-prompt.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: rgb(231, 231, 231) !important;
      background: linear-gradient(
        180deg,
        rgb(238, 238, 238) 0%,
        rgb(255, 255, 255) 100%
      );
      color: gray !important;
    }
    .active.btn-prompt.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      background-color: $primary !important;
      color: gray !important;
    }
  }
  .v-input {
    .v-input__control {
      .v-text-field__details {
        display: none;
      }
    }
  }
  .v-input.error--text {
    .v-input__control {
      .v-text-field__details {
        display: block;
      }
    }
  }
  .address-details {
    #form-base {
      .item {
        padding: 10px;
        .v-label,
        .v-label--active {
          display: none;
          transform: translateX(-10px) translateY(-45px) scale(1.1);
        }
      }
      .custom-style {
        * {
          background-color: transparent !important;
        }
      }
      .input-custom-title {
        color: $dark-secondary-color;
        font-size: 1rem;
      }
      .v-btn-toggle {
        .v-btn {
          background-color: rgb(231, 231, 231) !important;
          background: linear-gradient(
            180deg,
            rgb(238, 238, 238) 0%,
            rgb(255, 255, 255) 100%
          );
        }
        .v-btn--active {
          background: $light-primary-color !important;
          color: white !important;
        }
      }
    }

    .v-tab:not(:first-child) {
      margin-left: 10px;
    }
    .v-tabs {
      .v-tab {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        color: $primary !important;
        background-color: rgb(231, 231, 231) !important;
        background: linear-gradient(
          180deg,
          rgb(231, 231, 231) 0%,
          rgb(245, 245, 245) 100%
        );
      }
      .v-tab--active {
        background: $light-primary-color !important;
        color: white !important;
      }
    }
  }
}
</style>
