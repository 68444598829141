<template>
  <div>
    <template v-for="(form, index) in address_detail_forms">
      <v-card-title
        :key="index + form.name + '-heading'"
        class="px-0 mx-0"
        style="border-bottom: solid black 1px"
      >
        <h3 class="font-weight-regular text-uppercase">
          <v-icon color="primary darken-1" :class="form.icon_class">{{
            form.icon
          }}</v-icon>
          {{ form.title }}
        </h3>
        <v-spacer></v-spacer>
        <v-btn
          icon
          v-if="form.is_hideable"
          :color="form.is_showing ? '' : 'primary'"
          @click="
            form.is_showing
              ? (form.is_showing = false)
              : (form.is_showing = true)
          "
        >
          <v-icon>
            {{
              form.is_showing
                ? "mdi-arrow-up-drop-circle"
                : "mdi-arrow-down-drop-circle"
            }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :key="index + form.name + '-form'"
        v-if="
          (form.is_hideable && form.is_showing) || form.is_hideable == false
        "
      >
        <div class="ma-2 mt-6 show-form-labels">
          <VFormBase
            @errorsList="(val) => parent.updateErrorsList(index, val)"
            :col="{ cols: 12 }"
            :model="form_data"
            :schema="form_structure[form.name]"
            @click="handleFormInputClick"
            @input="handleFormInputClick"
          >
            <template #slot-top-array-form-base-drivers_info="{ idx }">
              <div class="d-flex justify-space-between pa-4 ma-2 bold title">
                <div>Driver {{ idx + 1 }}</div>
                <v-btn @click="removeDriver(idx)" icon small color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #slot-top-array-form-base-vehicles_info="{ idx }">
              <div class="d-flex justify-space-between pa-4 ma-2 bold title">
                <div>Vehicle {{ idx + 1 }}</div>
                <v-btn @click="removeVehicle(idx)" icon small color="error">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <template #slot-item-key-form-base-vehicle_driver_assignment="{}">
              <div class="d-flex pa-0 ma-0">
                <v-simple-table style="width: 100%">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Drivers</th>
                        <template v-for="vehicle in form_data.vehicles_info">
                          <template
                            v-if="
                              form_data.vehicles_info.length &&
                              vehicle.year &&
                              vehicle.make &&
                              vehicle.model
                            "
                          >
                            <th
                              :key="`${vehicle.year} ${vehicle.make} ${vehicle.model}`"
                            >
                              {{
                                `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                              }}
                            </th>
                          </template>
                        </template>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(driver, driverIndex) in form_data.drivers_info"
                      >
                        <template
                          v-if="
                            form_data.drivers_info.length &&
                            driver.first_name &&
                            driver.last_name
                          "
                        >
                          <tr :key="`${driver.first_name} ${driver.last_name}`">
                            <td>
                              {{ `${driver.first_name} ${driver.last_name}` }}
                            </td>
                            <template
                              v-for="(
                                vehicle, vehicleIndex
                              ) in form_data.vehicles_info"
                            >
                              <template
                                v-if="
                                  form_data.vehicles_info.length &&
                                  vehicle.year &&
                                  vehicle.make &&
                                  vehicle.model
                                "
                              >
                                <td
                                  :key="`${vehicle.year} ${vehicle.make} ${vehicle.model}`"
                                >
                                  <v-text-field
                                    v-model="
                                      form_data.vehicle_driver_assignment[
                                        vehicleIndex
                                      ][driverIndex]
                                    "
                                    @input="
                                      vehicleDriverAssignmentCalculation(
                                        vehicleIndex,
                                        driverIndex
                                      )
                                    "
                                    class="pa-0 my-4"
                                    type="number"
                                    suffix="%"
                                    clearable
                                    solo
                                    outlined
                                    dense
                                    :rules="[required()]"
                                  ></v-text-field>
                                </td>
                              </template>
                            </template>
                          </tr>
                        </template>
                      </template>
                      <tr>
                        <td class="text-start">Total Usage Per Vehicle</td>
                        <template
                          v-for="(
                            vehicle, vehicleIndex
                          ) in form_data.vehicle_driver_assignment"
                        >
                          <td
                            v-if="vehicle.total"
                            :key="vehicleIndex"
                            class="text-start"
                          >
                            {{ vehicle.total }}%
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </template>
          </VFormBase>
        </div>
      </v-card-text>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VFormBase from "vuetify-form-base";
import { required, validEmail, minLen } from "@/plugins/validationRules.js";

const firstUpperCase = ({ data, value, obj }) => {
  if (value)
    return `${value.toString()[0].toUpperCase()}${value.toString().slice(1)}`;
  return value;
};

export default {
  props: {
    application_id: {
      required: true,
    },
    parent: {
      required: true,
    },
  },
  components: {
    VFormBase,
  },
  data() {
    return {
      errorsList: [],
      isValidForm: false,
      address_detail_forms: [],
    };
  },
  computed: {
    ...mapState({
      form_data: (state) => state.application.form_data,
      application: (state) => state.application.application,
    }),
    application_details_form_selected_page: {
      set(val) {
        this.$store.state.application.application_details_form_selected_page =
          val;
      },
      get() {
        return this.$store.state.application
          .application_details_form_selected_page;
      },
    },
    form_structure() {
      return {
        client_info: {
          first_name: {
            type: "text",
            name: "first_name",
            label: "First Name",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          last_name: {
            type: "text",
            name: "last_name",
            label: "Last Name",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            toCtrl: firstUpperCase,
            fromCtrl: firstUpperCase,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          phone: {
            type: "tel",
            name: "phone",
            label: "Phone",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            mask: "(###) ###-####",
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), minLen(14, "10 Digits required.")],
          },
          email: {
            type: "email",
            name: "email",
            label: "Email Address",
            ...this.$helpers.formFieldsBasicLayout,
            solo: false,
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required(), validEmail()],
          },
        },
        drivers_info: {
          add_driver: {
            type: "btn",
            name: "add_driver",
            label: "Add Driver",
            ...this.$helpers.formFieldsBasicLayout,
            col: 12,
          },
          drivers_info: {
            name: "drivers_info",
            type: "array",
            col: 12,
            schema: {
              first_name: {
                type: "text",
                name: "first_name",
                label: "First Name",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                toCtrl: firstUpperCase,
                fromCtrl: firstUpperCase,
                rules: [required()],
              },
              last_name: {
                type: "text",
                name: "last_name",
                label: "Last Name",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                toCtrl: firstUpperCase,
                fromCtrl: firstUpperCase,
                rules: [required()],
              },
              date_of_birth: {
                type: "text",
                name: "date_of_birth",
                label: "Date of birth",
                typeInt: "date",
                mask: "####-##-##",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [required()],
              },
              gender: {
                type: "select",
                name: "gender",
                label: "Gender",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                // appendOuterIcon: "mdi-refresh",
                items: ["Male", "Female"],
                rules: [required()],
              },
              marital_status: {
                type: "select",
                name: "marital_status",
                label: "Marital Status",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: ["Married", "Single"],
                rules: [required()],
              },
              relationship: {
                type: "select",
                name: "relationship",
                label: "Relationship",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                items: ["Insured", "Spouse", "Children", "Sibling", "Parent"],
                rules: [required()],
              },
              driver_license_no: {
                type: "text",
                name: "driver_license_no",
                label: "Driver's License number",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [],
              },
            },
          },
        },
        vehicle_info: {
          add_vehicle: {
            type: "btn",
            name: "add_vehicle",
            label: "Add Vehicle",
            ...this.$helpers.formFieldsBasicLayout,
            col: 12,
          },
          vehicles_info: {
            name: "vehicles_info",
            type: "array",
            col: 12,
            schema: {
              vin: {
                type: "text",
                name: "vin",
                label: "Vin",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [required()],
              },
              year: {
                type: "text",
                ext: "number",
                name: "year",
                label: "Year",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                mask: "####",
                rules: [required()],
              },
              make: {
                type: "text",
                name: "make",
                label: "Make",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [required()],
              },
              model: {
                type: "text",
                name: "model",
                label: "Model",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [required()],
              },
              purchase_date: {
                type: "text",
                name: "purchase_date",
                label: "Purchase Date",
                typeInt: "date",
                mask: "####-##-##",
                ...this.$helpers.formFieldsBasicLayout,
                solo: false,
                rules: [required()],
              },
            },
          },
        },
        vehicle_assignment: {
          vehicle_driver_assignment: {
            type: "card",
            name: "vehicle_driver_assignment",
            col: 12,
            schema: {},
          },
        },
        current_prior_carrier: {
          current_prior_insurance: {
            type: "select",
            name: "current_prior_insurance",
            label: "Current Prior Insurance",
            ...this.$helpers.formFieldsBasicLayout,
            class: this.$helpers.formFieldsBasicLayout.class + " custom-style",
            dense: true,
            items: ["Preferred"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          current_prior_company: {
            type: "select",
            name: "current_prior_company",
            label: "Current Prior Company",
            ...this.$helpers.formFieldsBasicLayout,
            class: this.$helpers.formFieldsBasicLayout.class + " custom-style",
            dense: true,
            items: ["Auto-Owners"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          current_prior_limits: {
            type: "select",
            name: "current_prior_limits",
            label: "Current Prior Limits",
            ...this.$helpers.formFieldsBasicLayout,
            class: this.$helpers.formFieldsBasicLayout.class + " custom-style",
            dense: true,
            items: ["100,000/300,000"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
        },
        coverages_limits: {
          cls_bi_limit: {
            type: "select",
            name: "cls_bi_limit",
            label: "CSL/BI Limit",
            ...this.$helpers.formFieldsBasicLayout,
            class: this.$helpers.formFieldsBasicLayout.class + " custom-style",
            dense: true,
            items: ["100,000/300,000"],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
          pd_limit: {
            type: "select",
            name: "pd_limit",
            label: "PB Limit",
            ...this.$helpers.formFieldsBasicLayout,
            class: this.$helpers.formFieldsBasicLayout.class + " custom-style",
            dense: true,
            items: [
              "10,000",
              "15,000",
              "20,000",
              "25,000",
              "50,000",
              "100,000",
              "150,000",
              "200,000",
              "250,000",
              "500,000",
              "750,000",
              "1,000,000",
            ],
            col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
            rules: [required()],
          },
        },
      };
    },
  },
  watch: {
    "form_data.drivers_info"(newVal) {
      // console.log(newVal);
      this.makeDiverVehicleAssignment();
    },
    "form_data.vehicles_info"(newVal) {
      // console.log(newVal);
      this.makeDiverVehicleAssignment();
    },
    "$store.state.application.form_data": {
      handler: function (val, oldVal) {
        if (val && this.application && this.application.client) {
          // this.$refs.form.validate();
          let client = this.application.client;
          let firstName = val.first_name;
          let lastName = val.last_name;

          this.form_data.client_name_id = `${
            firstName ? firstName.toString().toUpperCase() : ""
          } ${lastName ? lastName.toString().toUpperCase() : ""}/${
            this.application.application_id
          }`;

          // if (val.condo_unit_number != val.address2) {
          //   this.form_data.address2 = val.condo_unit_number;
          // }
        }
      },
      deep: true,
    },
  },
  created() {
    this.setAddressDetailFormsSections();
  },
  mounted() {
    this.$nextTick(function () {
      if (this.form_data?.drivers_info?.length == 0) {
        this.addNewDriver();
      }
    });
  },
  methods: {
    required,
    setAddressDetailFormsSections() {
      this.address_detail_forms = [
        {
          title: "Client Information",
          icon: "mdi-account-circle-outline",
          name: "client_info",
          is_hideable: false,
        },
        {
          title: "Drivers Info",
          icon: "mdi-information-variant",
          name: "drivers_info",
          is_hideable: false,
        },
        {
          title: "Vehicle Info",
          icon: "mdi-information-variant",
          name: "vehicle_info",
          is_hideable: false,
        },
        {
          title: "Vehicle Assignment",
          icon: "mdi-information-variant",
          name: "vehicle_assignment",
          is_hideable: false,
        },
        {
          title: "Current Prior Carrier",
          icon: "mdi-text-box-check",
          name: "current_prior_carrier",
          is_hideable: false,
          // is_showing: false,
          // hide: true
        },
        {
          title: "Coverages & Limits",
          icon: "mdi-text-box-check",
          name: "coverages_limits",
          is_hideable: false,
          // is_showing: false,
          // hide: true
        },
      ];
    },
    async handleFormInputClick({
      on,
      id,
      key,
      value,
      params,
      obj,
      data,
      schema,
      parent,
      index,
      event,
    }) {
      // console.log(
      // 	on,
      // 	id,
      // 	key,
      // 	value,
      // 	params,
      // 	obj,
      // 	data,
      // 	schema,
      // 	parent,
      // 	index,
      // 	event
      // );
      if (
        `${on} ${id} ${key} ${params.tag}` ==
        "click form-base gender append-outer"
      ) {
        this.$nextTick(function () {
          this.$store.dispatch("application/refreshApplicationGender");
        });
      }

      // console.log(`${on} ${id} ${key} ${value}`);

      if (`${on} ${id} ${key} ${value}` == "click form-base add_driver null") {
        this.addNewDriver();
      }
      if (`${on} ${id} ${key} ${value}` == "click form-base add_vehicle null") {
        this.addNewVehicle();
      }
    },
    async addNewDriver() {
      if (this.form_data?.drivers_info?.length >= 5) {
        return;
      }

      let isFirstVehicle = false;
      let executable = true;

      if (this.form_data?.drivers_info?.length == 0) {
        await this.$store
          .dispatch("application/refreshApplicationVehiclesInfo")
          .then((response) => {
            // console.log(response);
            if (!response) {
              isFirstVehicle = true;
            } else {
              executable = false;
            }
          });
      }

      if (executable) {
        this.form_data.drivers_info.push({
          first_name: isFirstDriver ? this.form_data.first_name : null,
          last_name: isFirstDriver ? this.form_data.last_name : null,
          date_of_birth: isFirstDriver ? this.form_data.date_of_birth : null,
          gender: isFirstDriver ? this.form_data.gender : null,
          marital_status: isFirstDriver ? "Single" : null,
          relationship: isFirstDriver ? "Insured" : null,
          driver_license_no: null,
        });
      }
    },
    removeDriver(index) {
      this.$nextTick(function () {
        this.form_data.drivers_info.splice(index, 1);
      });
    },
    async addNewVehicle() {
      if (this.form_data?.vehicles_info?.length >= 5) {
        return;
      }

      let isFirstVehicle = false;
      let executable = true;
      if (this.form_data?.vehicles_info?.length == 0) {
        await this.$store
          .dispatch("application/refreshApplicationVehiclesInfo")
          .then((response) => {
            // console.log(response);
            if (!response) {
              isFirstVehicle = true;
            } else {
              executable = false;
            }
          });
      }

      if (executable) {
        this.form_data.vehicles_info.push({
          vin: isFirstVehicle ? "3GCA4FA" : null,
          year: isFirstVehicle ? "2003" : null,
          make: isFirstVehicle ? "SHEVY TRUCK" : null,
          model: isFirstVehicle ? "AVALANCHE 1500" : null,
          purchase_date: null,
        });
      }
    },
    removeVehicle(index) {
      this.$nextTick(function () {
        this.form_data.vehicles_info.splice(index, 1);
      });
    },
    makeDiverVehicleAssignment() {
      this.form_data.vehicles_info.forEach((vehicle, vehicleIndex) => {
        this.form_data.vehicle_driver_assignment[vehicleIndex] = {
          total: 0,
        };
        this.form_data.drivers_info.forEach((driver, driverIndex) => {
          this.form_data.vehicle_driver_assignment[vehicleIndex][driverIndex] =
            null;
        });
      });
    },
    vehicleDriverAssignmentCalculation(vehicleIndex, driverIndex) {
      let obj = Object.assign({}, this.form_data.vehicle_driver_assignment);

      let value = obj[vehicleIndex][driverIndex];
      if (value > 100) obj[vehicleIndex][driverIndex] = "100";
      if (value < 0) obj[vehicleIndex][driverIndex] = "0";

      let values = Object.entries(obj[vehicleIndex]).filter(
        (entry) => !entry.includes("total")
      );

      let sum = values.reduce(function (previousValue, currentValue) {
        if (currentValue[1] && Number.parseInt(currentValue[1]))
          return previousValue + Number.parseInt(currentValue[1]);
        return previousValue;
      }, 0);

      obj[vehicleIndex].total = sum;

      this.$nextTick(function () {
        this.form_data.vehicle_driver_assignment = Object.assign({}, obj);
      });

      return this.form_data.vehicle_driver_assignment;
    },
  },
};
</script>

<style lang="scss">
.text-field-left-border::before {
  position: absolute;
}

.show-form-labels {
  .key-form-base-drivers_info.prop-drivers_info,
  .key-form-base-vehicles_info.prop-vehicles_info {
    display: flex !important;
    flex-direction: row;
    div[name="vehicles_info"],
    div[name="drivers_info"] {
      width: 100%;
    }
  }
  #form-base {
    padding-top: 10px !important;
    .item {
      &.type-form-base-btn {
        padding-top: 0 !important;
        // margin-bottom: 14px !important;
      }
      * {
        background-color: transparent !important;
      }

      fieldset legend {
        display: none !important;
      }

      .v-label,
      .v-label--active {
        font-size: 1rem !important;
        color: #343434 !important;
        display: inline-block !important;
        transform: translateX(-10px) translateY(-37px) scale(1) !important;
      }
      .v-text-field__details {
        display: inline-block !important;
      }
    }
  }
}
</style>
